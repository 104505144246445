import { createPath, type Path } from 'react-router-dom';
import HomePageUrlQueryMapper from '../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../models/system/home/queries/HomePageQuery';

function advertsListPageGetSeoUrl(query?: HomePageQuery): Path {
  const filteredQuery: HomePageQuery = {
    filters: {
      animal: query?.filters?.animal,
      type: query?.filters?.type,
      createdFrom: query?.filters?.createdFrom,
      createdTo: query?.filters?.createdTo,
    },
    location: query?.location,
    expand: query?.expand,
  };

  return HomePageUrlQueryMapper.factory().generateHomePageFriendlyUrl(filteredQuery);
}

function advertsListPageGetCanonicalUrl(websiteOrigin: string, query?: HomePageQuery): string {
  const parts: string[] = [
    websiteOrigin,
    createPath(advertsListPageGetSeoUrl(query)),
  ];

  return parts.join('');
}

export {
  advertsListPageGetCanonicalUrl,
  advertsListPageGetSeoUrl
};

