import { TAXONOMY_ANIMAL_CAT, TAXONOMY_ANIMAL_DOG, TAXONOMY_ANIMAL_OTHER, TAXONOMY_TYPE_ADOPT, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../../../constants/TaxonomyConstants';

const TitleMatrix: Record<string, Record<string, string>> = {
  [TAXONOMY_TYPE_LOST]: {
    [TAXONOMY_ANIMAL_DOG]: 'Zaginął pies',
    [TAXONOMY_ANIMAL_CAT]: 'Zaginął kot',
    [TAXONOMY_ANIMAL_OTHER]: 'Zagineło zwierzę',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    [TAXONOMY_ANIMAL_DOG]: 'Oddam psa',
    [TAXONOMY_ANIMAL_CAT]: 'Oddam kota',
    [TAXONOMY_ANIMAL_OTHER]: 'Oddam zwierzę',
  },
  [TAXONOMY_TYPE_FOUND]: {
    [TAXONOMY_ANIMAL_DOG]: 'Znaleziono psa',
    [TAXONOMY_ANIMAL_CAT]: 'Znaleziono kota',
    [TAXONOMY_ANIMAL_OTHER]: 'Znaleziono zwierzę',
  },
};

const TitleShelterMatrix: Record<string, Record<string, string>> = {
  ...TitleMatrix,
  [TAXONOMY_TYPE_LOST]: {
    ...TitleMatrix[TAXONOMY_TYPE_LOST],
  },
  [TAXONOMY_TYPE_ADOPT]: {
    ...TitleMatrix[TAXONOMY_TYPE_ADOPT],
    [TAXONOMY_ANIMAL_DOG]: 'Pies do adopcji',
    [TAXONOMY_ANIMAL_CAT]: 'Kot do adopcji',
  },
  [TAXONOMY_TYPE_FOUND]: {
    ...TitleMatrix[TAXONOMY_TYPE_FOUND],
  },
};

class AdvertSharedContentService {

  public static getAdvertLabel = (isShelter: true | undefined, type: string, animal: string) => {
    return isShelter
      ? AdvertSharedContentService.getShelterAdvertLabelByParams(type, animal)
      : AdvertSharedContentService.getAdvertLabelByParams(type, animal);
  };

  public static getAdvertLabelByParams = (type: string, animal: string = TAXONOMY_ANIMAL_OTHER): string => {
    const byType = TitleMatrix[type];

    return byType
      ? byType[animal] ?? ''
      : '';
  };

  public static getShelterAdvertLabelByParams = (type: string, animal: string = TAXONOMY_ANIMAL_OTHER): string => {
    const byType = TitleShelterMatrix[type];

    return byType
      ? byType[animal] ?? ''
      : '';
  };
}

export default AdvertSharedContentService;
