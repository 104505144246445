import type { Taxonomy } from '../../../framework/base/common/models/system/Taxonomy';
import { TAXONOMY_ANIMAL_CAT, TAXONOMY_ANIMAL_DOG, TAXONOMY_ANIMAL_OTHER, TAXONOMY_BLANK, TAXONOMY_TYPE_ADOPT, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../../constants/TaxonomyConstants';
import { Labels } from '../../helpers/Content';

const blank: Taxonomy = { code: TAXONOMY_BLANK, label: Labels.blank, };

const types: Taxonomy[] = [
  { code: TAXONOMY_TYPE_LOST, label: Labels.type[TAXONOMY_TYPE_LOST] ?? '' },
  { code: TAXONOMY_TYPE_FOUND, label: Labels.type[TAXONOMY_TYPE_FOUND] ?? '' },
  { code: TAXONOMY_TYPE_ADOPT, label: Labels.type[TAXONOMY_TYPE_ADOPT] ?? '' },
];

const animals: Taxonomy[] = [
  { code: TAXONOMY_ANIMAL_DOG, label: Labels.animal[TAXONOMY_ANIMAL_DOG] ?? '' },
  { code: TAXONOMY_ANIMAL_CAT, label: Labels.animal[TAXONOMY_ANIMAL_CAT] ?? '' },
  { code: TAXONOMY_ANIMAL_OTHER, label: Labels.animal[TAXONOMY_ANIMAL_OTHER] ?? '' },
];

class TaxonomyContentService {

  public static getTypes = (includeBlank = false): Taxonomy[] => {
    return [
      ...types,
      ...(includeBlank ? [blank] : [])
    ];
  };

  public static getTypeCodes = (includeBlank = false): string[] => {
    return TaxonomyContentService.getTypes(includeBlank).map(taxonomy => taxonomy.code);
  };

  public static getAnimals = (includeBlank = false): Taxonomy[] => {
    return [
      ...animals,
      ...(includeBlank ? [blank] : [])
    ];
  };

  public static getAnimalCodes = (includeBlank = false): string[] => {
    return TaxonomyContentService.getAnimals(includeBlank).map(taxonomy => taxonomy.code);
  };
}

export default TaxonomyContentService;
