import { TAXONOMY_ANIMAL_CAT, TAXONOMY_ANIMAL_DOG, TAXONOMY_ANIMAL_OTHER, TAXONOMY_TYPE_ADOPT, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../constants/TaxonomyConstants';

interface LabelsInterface {
  blank: string;
  type: Record<string, string>;
  animal: Record<string, string>;
}

export const Labels: LabelsInterface = {
  blank: 'Nieustawione',
  type: {
    [TAXONOMY_TYPE_LOST]: 'Zaginął',
    [TAXONOMY_TYPE_ADOPT]: 'Do adopcji',
    [TAXONOMY_TYPE_FOUND]: 'Znaleziono',
  },
  animal: {
    [TAXONOMY_ANIMAL_DOG]: 'Pies',
    [TAXONOMY_ANIMAL_CAT]: 'Kot',
    [TAXONOMY_ANIMAL_OTHER]: 'Inne',
  },
};
