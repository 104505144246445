import UIProviderEnum from './enums/UIProviderEnum';

export const ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150 = 150;

export const ENTITY_IMAGE_TILE_HEIGHT_150 = 150;

export const ENTITY_IMAGE_WIDTH_SMALL_300 = 300;
export const ENTITY_IMAGE_WIDTH_MEDIUM_600 = 600;
export const ENTITY_IMAGE_WIDTH_LARGE_1000 = 1000;

export const ENTITY_IMAGE_NO_IMAGE = '/app/item/no-image.svg';
export const ENTITY_IMAGE_NO_IMAGE_THUMBNAIL = '/app/item/no-image-thumbnail.svg';

export const ENTITY_IMAGE_WIDTH_THUMBNAIL_EDITOR = ENTITY_IMAGE_WIDTH_LARGE_1000;

export const UI_PROVIDER: UIProviderEnum = UIProviderEnum.ReactBootstrap;

export const PAGINATION_SORT_BY_CREATED_AT = '';
export const PAGINATION_SORT_BY_CREATED_AT_DESC = 'created-desc';
export const PAGINATION_SORT_BY_UPDATED_AT = 'updated';
export const PAGINATION_SORT_BY_UPDATED_AT_DESC = 'updated-desc';
export const PAGINATION_SORT_BY_TITLE = 'title';
export const PAGINATION_SORT_BY_TITLE_DESC = 'title-desc';

export const FORM_SIGNUP_USERNAME_FIELD = 'username';
export const FORM_SIGNUP_PASSWORD_FIELD = 'password';
