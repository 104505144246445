import type { Path } from 'react-router-dom';
import type { LocationDetailWithGeoApiModel } from '../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemWithParentApiModel } from '../../../../framework/gis/common/models/api/location/LocationListItemWithParentApiModel';
import { TAXONOMY_ANIMAL_OTHER, TAXONOMY_TYPE_ADOPT, TAXONOMY_TYPE_FOUND, TAXONOMY_TYPE_LOST } from '../../../constants/TaxonomyConstants';
import AdvertPublicCreatedAtPeriodEnum from '../../../constants/enums/AdvertPublicCreatedAtPeriodEnum';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import { HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT } from '../../../helpers/mappers/HomePageUrlQueryMapper';
import type { AdvertListItemApiModel } from '../../../models/api/advert/AdvertListItemApiModel';
import type { HomePageQuery } from '../../../models/system/home/queries/HomePageQuery';
import getFromMatrix from '../abstract/getFromMatrix';
import EntitiesListPageContentService from '../abstract/list/EntitiesListPageContentService';
import AdvertSharedContentService from './AdvertSharedContentService';
import { getByQuery } from './advertsListPageContentHelpers';
import { advertsListPageGetSeoUrl } from './advertsListPageUrlHelpers';

// Head title

const HeadTitleDefault = 'Zaginione i znalezione zwierzęta oraz zwierzęta do adopcji';

const HeadTitleDefaultRecent = 'Ostatnio zaginione i znalezione zwierzęta oraz zwierzęta do adopcji';

const HeadTitleMatrix = {
  [TAXONOMY_TYPE_LOST]: {
    cat: 'Zaginione koty',
    dog: 'Zaginione psy',
    other: 'Zaginione zwierzęta',
  },
  [TAXONOMY_TYPE_FOUND]: {
    cat: 'Znalezione koty',
    dog: 'Znalezione psy',
    other: 'Znalezione zwierzęta',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    cat: 'Koty do adpocji',
    dog: 'Psy do adopcji',
    other: 'Zwierzęta do adopcji',
  },
};

const HeadTitleMatrixRecent = {
  ...HeadTitleMatrix,
  lost: {
    ...HeadTitleMatrix.lost,
    cat: 'Ostatnio zaginione koty',
    dog: 'Ostatnio zaginione psy',
    other: 'Ostatnio zaginione zwierzęta',
  },
  found: {
    ...HeadTitleMatrix.found,
    cat: 'Ostatnio znalezione koty',
    dog: 'Ostatnio znalezione psy',
    other: 'Ostatnio znalezione zwierzęta',
  },
};

const HeadTitleByAnimal = {
  dog: 'Zaginione i znalezione psy',
  cat: 'Zaginione i znalezione koty',
  other: HeadTitleDefault,
};

const HeadTitleByAnimalRecent = {
  dog: 'Ostatnio zaginione i znalezione psy',
  cat: 'Ostatnio zaginione i znalezione koty',
  other: HeadTitleDefaultRecent,
};

// Head meta description

const HeadMetaDescriptionDefault = 'Strona z ogłoszeniami o zaginionych i znalezionych zwierzętach oraz zwierzętach do adopcji.';

const HeadMetaDescriptionMatrix = {
  lost: {
    dog: 'Ogłoszenia o zaginionych psach. Znalazłeś(aś) psa? Przejrzyj listę ogłoszeń o poszukiwanych psach w Twojej okolicy.',
    cat: 'Ogłoszenia o zaginionych kotach. Znalazłeś(aś) kota? Przejrzyj listę ogłoszeń o poszukiwanych kotach w Twojej okolicy.',
    other: 'Ogłoszenia o zaginionych zwierzętach. Znalazłeś(aś) zwierzę? Przejrzyj listę ogłoszeń o poszukiwanych zwierzętach w Twojej okolicy.',
  },
  found: {
    dog: 'Ogłoszenia o znalezionych psach. Zaginął pies? Przejrzyj listę ogłoszeń o znalezionych psach w Twojej okolicy.',
    cat: 'Ogłoszenia o znalezionych kotach. Zaginął kot? Przejrzyj listę ogłoszeń o znalezionych kotach w Twojej okolicy.',
    other: 'Ogłoszenia o znalezionych zwierzętach. Zaginął zwierzak? Przejrzyj listę ogłoszeń o znalezionych zwięrzętach w Twojej okolicy.',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    dog: 'Psy do adopcji. Chcesz mieć psa? Przejrzyj listę ogłoszeń o psach do oddania w Twojej okolicy.',
    cat: 'Koty do adopcji. Chcesz mieć kota? Przejrzyj listę ogłoszeń o kotach do oddania w Twojej okolicy.',
    other: 'Zwierzęta do adopcji. Chcesz mieć zwierzaka? Przejrzyj listę ogłoszeń o zwierzętach do oddania w Twojej okolicy.',
  },
};

const HeadMetaDescriptionByAnimal = {
  dog: 'Strona z ogłoszeniami o zaginionych i znalezionych psach oraz psach do adopcji.',
  cat: 'Strona z ogłoszeniami o zaginionych i znalezionych kotach oraz kotach do adopcji.',
  other: HeadMetaDescriptionDefault,
};

// Page header

const PageHeaderDefault = 'Ogłoszenia';

// Link title

const LinkLabelDefault = 'Zobacz ogłoszenia';

const LinkLabelMatrix = {
  lost: {
    dog: 'Zobacz ogłoszenia o zaginionych psach',
    cat: 'Zobacz ogłoszenia o zaginionych kotach',
    other: 'Zobacz ogłoszenia o zaginionych zwierzakach',
  },
  found: {
    dog: 'Zobacz ogłoszenia o znalezionych psach',
    cat: 'Zobacz ogłoszenia o znalezionych kotach',
    other: 'Zobacz ogłoszenia o znalezionych zwierzakach',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    dog: 'Zobacz ogłoszenia o psach do adopcji',
    cat: 'Zobacz ogłoszenia o kotach do adopcji',
    other: 'Zobacz ogłoszenia o zwierzakach do adopcji',
  },
};

// Breadcrumb Link Label

const BreadcrumbLinkLabelDefault = 'Ogłoszenia';

const BreadcrumbLinkLabelsByAnimal = {
  dog: 'Psy',
  cat: 'Koty',
  other: 'Inne zwierzęta',
};

const BreadcrumbLinkLabelsMatrix = HeadTitleMatrix;

const BreadcrumbLinkLabelsRecentMatrix = {
  ...BreadcrumbLinkLabelsMatrix,
  lost: {
    cat: 'Ostatnio zaginione koty',
    dog: 'Ostatnio zaginione psy',
    other: 'Ostatnio zaginione zwierzęta',
  },
  found: {
    cat: 'Ostatnio znalezione koty',
    dog: 'Ostatnio znalezione psy',
    other: 'Ostatnio znalezione zwierzęta',
  },
};

// Breadcrumb Link Title

const BreadcrumbLinkTitleDefault = HeadTitleDefault;

const BreadcrumbLinkTitlesByAnimal = {
  cat: 'Zaginął kot / Znaleziono kota / Koty do adopcji',
  dog: 'Zaginął pies / Znaleziono psa / Psy do adopcji',
  other: HeadTitleDefault,
};

const BreadcrumbLinkTitlesMatrix = {
  lost: {
    cat: 'Zaginął kot',
    dog: 'Zaginął pies',
    other: 'Zagineło zwierzę',
  },
  found: {
    cat: 'Znaleziono kota',
    dog: 'Znaleziono psa',
    other: 'Znaleziono zwierzę',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    cat: 'Kot do adopcji',
    dog: 'Pies do adopcji',
    other: 'Zwierzę do adopcji',
  },
};

// Breadcrumb Schema Name

const BreadcrumbSchemaNameDefault = 'Ogłoszenia';

const BreadcrumbSchemaNamesByAnimal = {
  dog: 'Psy',
  cat: 'Koty',
  other: 'Inne zwierzęta',
};

const BreadcrumbSchemaNamesMatrix = {
  lost: {
    cat: 'Zaginione',
    dog: 'Zaginione',
    other: 'Zaginione',
  },
  found: {
    cat: 'Znalezione',
    dog: 'Znalezione',
    other: 'Znalezione',
  },
  [TAXONOMY_TYPE_ADOPT]: {
    cat: 'Do adpocji',
    dog: 'Do adpocji',
    other: 'Do adpocji',
  },
};

function isRecentPeriod(query?: HomePageQuery): boolean {
  if ([
    AdvertPublicCreatedAtPeriodEnum.Recent2Weeks,
    AdvertPublicCreatedAtPeriodEnum.RecentWeek,
  ].includes(query?.filters?.advertPublicCreatedAtPeriod ?? HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT)) {
    return true;
  } else {
    return false;
  }
}

class AdvertsListPageContentService extends EntitiesListPageContentService {

  public static getCategoryLinkLabelStatic = (query?: HomePageQuery): string => {
    return getByQuery(BreadcrumbLinkLabelsMatrix, BreadcrumbLinkLabelsByAnimal, BreadcrumbLinkLabelDefault, query);
  };

  public static getCategoryLinkLabelRecentStatic = (query?: HomePageQuery): string => {
    return getByQuery(BreadcrumbLinkLabelsRecentMatrix, BreadcrumbLinkLabelsByAnimal, BreadcrumbLinkLabelDefault, query);
  };

  public static getCategoryLinkTitleStatic = (query?: HomePageQuery): string => {
    return getByQuery(BreadcrumbLinkTitlesMatrix, BreadcrumbLinkTitlesByAnimal, BreadcrumbLinkTitleDefault, query);
  };

  public static getLinkLabel = (type?: string, animal: string = TAXONOMY_ANIMAL_OTHER): string => {
    return !type
      ? LinkLabelDefault
      : getFromMatrix(LinkLabelMatrix, type, animal);
  };

  public static getAdvertLabel = (item: AdvertListItemApiModel): string => {
    return AdvertSharedContentService.getAdvertLabel(item.isShelter ? true : undefined, item.type, item.animal);
  };

  private static getSchemaLabelStatic = (query?: HomePageQuery): string => {
    return getByQuery(BreadcrumbSchemaNamesMatrix, BreadcrumbSchemaNamesByAnimal, BreadcrumbSchemaNameDefault, query);
  };

  constructor(location: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel | undefined) {
    super({
      headTitle: HeadTitleDefault,
      headMetaDescription: HeadMetaDescriptionDefault,
      pageHeader: PageHeaderDefault,
    }, process.env['CLIENT_BUILD_WEBSITE_ORIGIN'] || '', location);
  }

  public override getHeadTitle(query?: HomePageQuery): string {
    const locationParent = this.location?.parent?.parent ? `, ${this.location.parent.name}` : '';
    const location = this.location ? ` ${this.location.name}${locationParent}` : '';
    const isRecent = isRecentPeriod(query);
    const headTitle = isRecent
      ? getByQuery(HeadTitleMatrixRecent, HeadTitleByAnimalRecent, HeadTitleDefaultRecent, query)
      : getByQuery(HeadTitleMatrix, HeadTitleByAnimal, super.getHeadTitle(), query);

    return `${headTitle}${location}${isRecent ? '' : ' - Ogłoszenia'}`;
  }

  public override getHeadMetaDescription = (query?: HomePageQuery): string => {
    return getByQuery(HeadMetaDescriptionMatrix, HeadMetaDescriptionByAnimal, super.getHeadMetaDescription(), query);
  };

  public override getSeoUrl = (query?: HomePageQuery): Path => {
    return advertsListPageGetSeoUrl(query);
  };

  public getBreadcrumbLinkLabel = (query?: HomePageQuery): string => {
    return AdvertsListPageContentService.getCategoryLinkLabelStatic(query);
  };

  public getBreadcrumbLinkTitle = (query?: HomePageQuery): string => {
    return AdvertsListPageContentService.getCategoryLinkTitleStatic(query);
  };

  public getBreadcrumbSchemaName = (query?: HomePageQuery): string => {
    return AdvertsListPageContentService.getSchemaLabelStatic(query);
  };

  public override createPageTitle(query: HomePageQuery): string {
    return isRecentPeriod(query)
      ? this.getHeadTitle(query)
      : super.createPageTitle(query);
  }

  protected getEntityType(): EntityTypeEnum {
    return EntityTypeEnum.Advert;
  }
}

export default AdvertsListPageContentService;
