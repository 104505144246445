import loadable from '@loadable/component';
import React, { useCallback, useEffect, useMemo, useState, type MouseEventHandler } from 'react';
import type { DictionaryItemModel } from '../../../../../../framework/base/common/models/entities/base/DictionaryItemModel';
import type { IHomePageUrlGenerator } from '../../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import AdvertPublicCreatedAtPeriodEnum from '../../../../../constants/enums/AdvertPublicCreatedAtPeriodEnum';
import { HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT } from '../../../../../helpers/mappers/HomePageUrlQueryMapper';
import styles from './AdvertPublicCreatedAtPeriodDropdownNative.module.scss';

const AdvertPublicCreatedAtPeriodDropdownNativeOpened = loadable(() => import(/* webpackChunkName: "page-home-adverts-list-period-opened" */'./AdvertPublicCreatedAtPeriodDropdownNativeOpened'));

const periods: DictionaryItemModel<AdvertPublicCreatedAtPeriodEnum>[] = [
  { key: AdvertPublicCreatedAtPeriodEnum.RecentWeek, value: 'Ostatni tydzień' },
  { key: AdvertPublicCreatedAtPeriodEnum.Recent2Weeks, value: 'Ostatnie 2 tygodnie' },
  { key: AdvertPublicCreatedAtPeriodEnum.RecentMonth, value: 'Ostatni miesiąc' },
  { key: AdvertPublicCreatedAtPeriodEnum.Recent2Months, value: 'Ostatnie 2 miesięce' },
];

interface AdvertPublicCreatedAtPeriodDropdownProps {
  urlGenerator: IHomePageUrlGenerator;
}

const AdvertPublicCreatedAtPeriodDropdownNative: React.FC<AdvertPublicCreatedAtPeriodDropdownProps> = ({
  urlGenerator
}: AdvertPublicCreatedAtPeriodDropdownProps) => {

  const [isOpen, setIsOpen] = useState(false);

  const selectedLabel = useMemo<string | undefined>(() => {
    const code = urlGenerator.query.filters?.advertPublicCreatedAtPeriod ?? HOME_PAGE_ADVERT_FILTERS_PUBLIC_CREATED_PERDIO_DEFAULT;
    const value = periods.find((period) => period.key === code);

    return value?.value;
  }, [urlGenerator.query.filters?.advertPublicCreatedAtPeriod]);

  const handleOnClickButton: MouseEventHandler<HTMLButtonElement> = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setIsOpen((state) => !state);
  }, []);

  const handleOnClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOnClickOutside);

      return () => {
        document.removeEventListener('click', handleOnClickOutside);
      };
    }

    return undefined;
  }, [handleOnClickOutside, isOpen]);

  return (
    <div id={styles['app-div']}>
      <button
        id={styles['app-button']}
        type='button'
        onClick={handleOnClickButton}
      >
        {selectedLabel}
      </button>
      {isOpen && (
        <AdvertPublicCreatedAtPeriodDropdownNativeOpened
          urlGenerator={urlGenerator}
          periods={periods}
        />
      )}
    </div>
  );
};

export default AdvertPublicCreatedAtPeriodDropdownNative;
