import AppRouterFriendlyUrlRepository from '../components/layout/routers/AppRouterFriendlyUrlRepository';

interface AdvertForLink {
  id: string;
  slug: string | undefined;
  animal: string;
  type: string;
}

function advertUrlHelperGetEntityDetailPathname(advert: AdvertForLink, domain?: string): string {
  if (advert.slug) {
    const animal = AppRouterFriendlyUrlRepository.pathByAnimalTaxonomy[advert.animal];
    const type = AppRouterFriendlyUrlRepository.pathByTypeTaxonomy[advert.type];

    if (animal && type) {
      const result = (domain ? domain : '') + animal + type + '/' + advert.slug;

      return result;
    }
  }

  return `${domain ? domain : ''}/ad/${advert.id}`;
}

export type {
  AdvertForLink
};

export {
  advertUrlHelperGetEntityDetailPathname
};

