import type { IModule } from '../../../../../framework/base/common/store/IStoreModule';
import homeAdvertsReducer from './homeAdvertsReducer';
import type { IHomeAdvertsAwareState } from './models/IHomeAdvertsAwareState';

const homeAdvertsModule: IModule<IHomeAdvertsAwareState> = {
  id: 'home-adverts',
  reducersMap: {
    homeAdverts: homeAdvertsReducer,
  },
};

export default homeAdvertsModule;
