import HomeAdvertsService from '../../../../../client/services/home/HomeAdvertsService';
import type { AdvertListItemHomePageApiModel } from '../../../../models/api/advert/specified/AdvertListItemHomePageApiModel';
import type { HomeAdvertsDataModel } from '../../../../models/home/adverts/HomeAdvertsDataModel';
import baseHomeEntitiesActionCreatorsFactory from '../base/baseHomeEntitiesActionCreatorsFactory';
import baseHomeEntitiesReducerFactory from '../base/baseHomeEntitiesReducerFactory';
import type { HomeAdvertsState } from './models/HomeAdvertsState';

const namespace = 'home-adverts';

const homeAdvertsActionCreators = baseHomeEntitiesActionCreatorsFactory<HomeAdvertsDataModel>(namespace, HomeAdvertsService);

const homeAdvertsReducer = baseHomeEntitiesReducerFactory<HomeAdvertsState, HomeAdvertsDataModel, AdvertListItemHomePageApiModel>(
  namespace,
  {},
);

export {
  homeAdvertsActionCreators
};

export default homeAdvertsReducer;
