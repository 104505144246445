import { TAXONOMY_ANIMAL_OTHER } from '../../../constants/TaxonomyConstants';
import type { HomePageQuery } from '../../../models/system/home/queries/HomePageQuery';
import type { HomePageQueryFilters } from '../../../models/system/home/queries/HomePageQueryFilters';

function getTypesAndAnimalsFromQuery(filters?: HomePageQueryFilters | undefined): { types?: string[] | undefined, animals?: string[] | undefined; } {
  const types = filters?.type;
  const animals = filters?.animal;

  return { types, animals };
}

function getTypeAndAnimalFromQuery(filters?: HomePageQueryFilters | undefined): { type?: string | undefined, animal?: string | undefined; } {
  const { types, animals } = getTypesAndAnimalsFromQuery(filters);

  const type = types?.length === 1 ? types[0] : undefined;
  const animal = animals?.length === 1 ? animals[0] : undefined;

  return { type, animal };
}

function getByQuery(matrix: Record<string, Record<string, string>>, byAnimal: Record<string, string>, defaultText: string, query?: HomePageQuery): string {
  const { type, animal } = getTypeAndAnimalFromQuery(query?.filters);

  const singleType = type;
  const singleAnimal = animal;

  if (singleType && singleAnimal) {
    const bySingleType = matrix[singleType];

    if (bySingleType) {
      const bySingleAnimal = bySingleType[singleAnimal];

      if (bySingleAnimal) {
        return bySingleAnimal;
      }
    }
  }

  if (singleType) {
    const bySingleType = matrix[singleType];

    if (bySingleType) {
      const bySingleAnimal = bySingleType[TAXONOMY_ANIMAL_OTHER];

      if (bySingleAnimal) {
        return bySingleAnimal;
      }
    }
  }

  if (singleAnimal) {
    const bySingleAnimal = byAnimal[singleAnimal];

    if (bySingleAnimal) {
      return bySingleAnimal;
    }
  }

  return defaultText;
}

export {
  getByQuery
};

