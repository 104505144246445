import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import { isSlugsEqual } from '../../../../../framework/gis/common/components/shared/elements/map/main/hooks/useMainMapLocationData';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type { LocationDetailWithGeoApiModel } from '../../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemWithParentApiModel } from '../../../../../framework/gis/common/models/api/location/LocationListItemWithParentApiModel';
import type { BaseLatLngUsersEntitiesQueryLocation } from '../../../../../framework/gis/common/models/queries/base/BaseLatLngUsersEntitiesQuery';
import type { MapParamsQuery } from '../../../../../framework/gis/common/models/system/map/queries/MapParamsQuery';
import type { IMapAwareState } from '../../../../../framework/gis/common/store/modules/map/models/IMapAwareState';
import EntityTypeEnum from '../../../../constants/enums/EntityTypeEnum';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import type { HomePageQueryFilters } from '../../../../models/system/home/queries/HomePageQueryFilters';
import { homeAdvertsActionCreators } from '../../../../store/modules/home/adverts/homeAdvertsReducer';
import type { IHomeAdvertsAwareState } from '../../../../store/modules/home/adverts/models/IHomeAdvertsAwareState';

const getLocations = (location: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel): (LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel)[] => {
  const parents = location.parent
    ? getLocations(location.parent)
    : [];
  return [
    ...parents,
    location,
  ];
};

function isFiltersDifferent(url: HomePageQueryFilters | undefined, state: HomePageQueryFilters | undefined): boolean {
  const urlToCompare = JSON.stringify(url ?? {});
  const stateToCompare = JSON.stringify(state ?? {});
  const result = urlToCompare !== stateToCompare;

  return result;
}

function isMapDifferent(url: MapParamsQuery | undefined, state: MapParamsQuery | undefined): boolean {
  const urlToCompare = JSON.stringify(url);
  const stateToCompare = JSON.stringify(state);
  const result = urlToCompare !== stateToCompare;

  return result;
}

function isLocationDifferent(
  url: BaseLatLngUsersEntitiesQueryLocation | undefined,
  state: BaseLatLngUsersEntitiesQueryLocation | undefined,
  locationData: LocationDetailWithGeoApiModel | undefined,
): boolean {
  if (state === undefined && url === undefined) {
    return false;
  }

  if (state === undefined || url === undefined) {
    return true;
  }

  // TODO 
  // first render could be queried by slugs
  // 1) /psy/znalezione/wojewodztwo-zachodniopomorskie?...
  // after opening modal
  // 2) /psy/znalezione/lo-93-id-1224-zleceniodawca-ug-lobez-obszar-18-lipca-2023-choszczno-wsyb6r?location=62cba9c880069779060efadb...
  // location is transfered to by ID - but this is the same location
  // don't re-query in that case

  const { id: urlId, slugs: urlSlugs } = url;
  const { id: stateId, slugs: stateSlugs } = state;

  if (urlId !== stateId) {
    if (stateId === undefined && stateSlugs !== undefined && locationData && urlId === locationData.id) {
      const locations = getLocations(locationData);
      const locationSlugs = locations.map((x) => x.localNameSlug);

      if (JSON.stringify(stateSlugs) === JSON.stringify(locationSlugs)) {
        return false;
      }
    }

    return true;
  }

  if (!isSlugsEqual(urlSlugs, stateSlugs)) {
    return true;
  }

  return false;
}

function isHomePageQueryDifferent(
  url: DeepReadonly<HomePageQuery>,
  state: DeepReadonly<HomePageQuery> | undefined,
  locationData: LocationDetailWithGeoApiModel | undefined,
): boolean {

  if (!state) {
    return true;
    // const populatedKeys: string[] = [];

    // for (const key in url) {
    //   if (key !== 'expand') {
    //     const value = (url as Record<string, unknown>)[key]; // TODO
    //     if (value) {
    //       if (typeof value === 'object') {
    //         if (Object.keys(value).length > 0) {
    //           populatedKeys.push(key);
    //         }
    //       } else {
    //         populatedKeys.push(key);
    //       }
    //     }
    //   }
    // }

    // if (populatedKeys.length > 0) {
    //   return true;
    // } else {
    //   return true;
    // }
  }

  // Not important
  // url.active;
  // url.activePin;
  // url.expand;
  // url.pagination; (handled as load more)

  // Important
  // url.filters;
  // url.location;
  // url.map;
  // url.tab;

  // url.filters;
  if (isFiltersDifferent(url.filters, state?.filters)) {
    return true;
  }

  // url.location;
  if (isLocationDifferent(url.location, state?.location, locationData)) {
    return true;
  }

  // url.map;
  if (isMapDifferent(url.map, state?.map)) {
    return true;
  }

  // url.tab;
  if ((url.tab ?? EntityTypeEnum.Advert) !== (state?.tab ?? EntityTypeEnum.Advert)) {
    return true;
  }

  return false;
}

interface UseHomePageContentItemsDataProps {
  isDetail: boolean | undefined;
  urlGenerator: IHomePageUrlGenerator;
}

// let renderCounter = 0;

const useHomePageAdvertsContentItemsData = ({ urlGenerator, isDetail }: UseHomePageContentItemsDataProps) => {

  // console.debug(`Render useHomePageAdvertsContentItemsData ${++renderCounter}`);

  const query = useSelector((state: IHomeAdvertsAwareState) => state.homeAdverts?.query);
  const locationData = useSelector((state: IMapAwareState): LocationDetailWithGeoApiModel | undefined => state.mapLocation?.data);

  const dispatch: ThunkDispatch<never, never, Action> = useDispatch();

  useEffect(() => {
    if ((urlGenerator.query.tab ?? EntityTypeEnum.Advert) !== (query?.tab ?? EntityTypeEnum.Advert)) {
      // console.log('Clear home when tab changed', 'url', urlGenerator.query.tab, 'query', query?.tab);
      dispatch(homeAdvertsActionCreators.clear());
    }
  }, [dispatch, query?.tab, urlGenerator.query.tab]);

  useEffect(() => {
    if (!isDetail) {
      if (isHomePageQueryDifferent(urlGenerator.query, query, locationData)) {
        dispatch(homeAdvertsActionCreators.fetchByQueryAsync(urlGenerator.query));
      } else {
        if (JSON.stringify(urlGenerator.query.pagination) !== JSON.stringify(query?.pagination)) {
          dispatch(homeAdvertsActionCreators.fetchPageByQueryAsync(urlGenerator.query));
        }
      }
    }
  }, [dispatch, isDetail, locationData, query, urlGenerator.query]);

  useEffect(() => () => {
    dispatch(homeAdvertsActionCreators.clear());
  }, [dispatch]);
};

export default useHomePageAdvertsContentItemsData;
