import React from 'react';
import DynamicModuleLoaderWrapper from '../../../../framework/base/common/components/shared/elements/DynamicModuleLoaderWrapper';
import type { IHomePageUrlGenerator } from '../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import homeAdvertsModule from '../../../store/modules/home/adverts/homeAdvertsModule';
import AdvertsList from './adverts/AdvertsList';

interface HomePageContentItemsAdvertsProps {
  isDetail: boolean | undefined;
  urlGenerator: IHomePageUrlGenerator;
}

// let renderCounter = 0;

const HomePageContentItemsAdverts: React.FC<HomePageContentItemsAdvertsProps> = ({ isDetail, urlGenerator }: HomePageContentItemsAdvertsProps) => {

  // console.debug(`Render HomePageContentItemsAdverts ${++renderCounter}`);

  return (
    <DynamicModuleLoaderWrapper module={homeAdvertsModule}>
      <AdvertsList
        isDetail={isDetail}
        urlGenerator={urlGenerator}
      />
    </DynamicModuleLoaderWrapper>
  );
};

export default HomePageContentItemsAdverts;
