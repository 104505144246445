import React, { useCallback, useEffect, useState } from 'react';
import styles from './AdvertsListItemHomePagePinButton.module.scss';

function helperText(isActive: boolean): string | undefined {
  return isActive ? 'Zaznaczono na mapie' : undefined;
}

interface AdvertsListItemHomePagePinButtonProps {
  isActive: boolean;
  handleOnClickPin: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// let renderCounter = 0;

const AdvertsListItemHomePagePinButton: React.FC<AdvertsListItemHomePagePinButtonProps> = ({
  isActive,
  handleOnClickPin
}: AdvertsListItemHomePagePinButtonProps) => {

  // console.debug(`Render AdvertsListItemHomePagePinButton ${++renderCounter}`);

  const [text, setText] = useState(helperText(isActive));

  useEffect(() => {
    setText(helperText(isActive));
  }, [isActive]);

  const handleOnMouseOver = useCallback(() => {
    if (!isActive) {
      setText('Zaznacz na mapie');
    }
  }, [isActive]);

  const handleOnMouseOut = useCallback(() => {
    if (!isActive) {
      setText(undefined);
    }
  }, [isActive]);

  return (
    <button
      title={isActive ? 'Odznacz' : 'Zaznacz na mapie'}
      className={`${styles['app-button-pin']}${isActive ? ` ${styles['active']}` : ''}`}
      onClick={handleOnClickPin}
      onMouseOver={handleOnMouseOver}
      onFocus={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
      onBlur={handleOnMouseOut}
    >
      {text && (<span>{text}</span>)}
    </button>
  );
};

export default AdvertsListItemHomePagePinButton;
