import loadable from '@loadable/component';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EntityPageMetadata from '../../../../../framework/base/common/components/pages/entity/EntityPageMetadata';
import { useAppLocalizationContext } from '../../../../../framework/base/common/contexts/AppLocalizationContext';
import createEntitiesListItemsListSelector from '../../../../../framework/base/common/store/selectors/helpers/createEntitiesListItemsListSelector';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type { LocationDetailWithGeoApiModel } from '../../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { IMapAwareState } from '../../../../../framework/gis/common/store/modules/map/models/IMapAwareState';
import AdvertsListPageContentService from '../../../../services/content/advert/AdvertsListPageContentService';
import AdvertsListBreadcrumbsService from '../../../../services/content/breadcrumbs/AdvertsListBreadcrumbsService';
import EntitiesListBreadcrumbsService from '../../../../services/content/breadcrumbs/abstract/EntitiesListBreadcrumbsService';
import type { IHomeAdvertsAwareState } from '../../../../store/modules/home/adverts/models/IHomeAdvertsAwareState';
import HomePageEntitiesList from '../components/HomePageEntitiesList';
import HomePageEntitiesListHeader from '../components/HomePageEntitiesListHeader';
import useHomePageAdvertsContentItemsData from '../hooks/useHomePageAdvertsContentItemsData';
import styles from './AdvertsList.module.scss';
import AdvertsListFilters from './AdvertsListFilters';
import AdvertsListItemHomePage from './AdvertsListItemHomePage';

const AdvertsListNoItems = loadable(() => import('./AdvertsListNoItems'));

const advertBreadcrumbsService = new AdvertsListBreadcrumbsService();

interface AdvertsListProps {
  urlGenerator: IHomePageUrlGenerator;
  isDetail?: boolean | undefined;
}

// let renderCounter = 0;

const AdvertsList: React.FC<AdvertsListProps> = ({ isDetail, urlGenerator }: AdvertsListProps) => {

  // console.debug(`Render AdvertsList ${++renderCounter}`);

  const localizationContext = useAppLocalizationContext();

  useHomePageAdvertsContentItemsData({ urlGenerator, isDetail });

  // const isFetching = useSelector((state: IHomeAdvertsAwareState) => state?.homeAdverts?.isFetching);
  const locationData = useSelector((state: IMapAwareState): LocationDetailWithGeoApiModel | undefined => state.mapLocation?.data);

  const advertsListPageContentService = useMemo(() => new AdvertsListPageContentService(locationData), [locationData]);

  const breadcrumbs = advertBreadcrumbsService.createBreadcrumbsForList(urlGenerator.query, locationData);
  const rssFeedUrls = advertBreadcrumbsService.createRssFeedAlternativeUrls(urlGenerator.query, locationData);

  return (
    <>
      {!isDetail && (
        <EntityPageMetadata
          pageContentService={advertsListPageContentService}
          pageContentServiceParams={urlGenerator.query}
        >
          <script type='application/ld+json'>
            {JSON.stringify(EntitiesListBreadcrumbsService.createBreadcrumbsSchema(breadcrumbs))}
          </script>
          {rssFeedUrls.map((url, index) => (
            <link
              key={index}
              rel='alternate'
              type='application/rss+xml'
              href={url}
            />
          ))}
        </EntityPageMetadata>
      )}
      <HomePageEntitiesListHeader
        entitiesListPageContentService={advertsListPageContentService}
        query={urlGenerator.query}
        breadcrumbs={breadcrumbs}
      />
      <AdvertsListFilters urlGenerator={urlGenerator} />
      <HomePageEntitiesList
        selectors={createEntitiesListItemsListSelector((state: IHomeAdvertsAwareState) => state.homeAdverts?.data?.entities)}
        selectorError={(state: IHomeAdvertsAwareState) => state.homeAdverts.error}
        querySelector={(state: IHomeAdvertsAwareState) => state.homeAdverts.query}
        urlGenerator={urlGenerator}
        listClassName={styles['app-advert-ul']}
        render={(item, locationSearch, index, isActive, handleOnClickPin) => (
          <AdvertsListItemHomePage
            index={index}
            item={item}
            locationSearch={locationSearch}
            isActive={isActive}
            formatDate={localizationContext.formatDate}
            handleOnClickPin={handleOnClickPin}
          />
        )}
        noItemsComponent={AdvertsListNoItems}
      />
    </>
  );
};

export default AdvertsList;
