import type { HomeAdvertsDataModel } from '../../../common/models/home/adverts/HomeAdvertsDataModel';
import type { HomePageQuery } from '../../../common/models/system/home/queries/HomePageQuery';
import BaseHomeEntitiesService from './base/BaseHomeEntitiesService';

class HomeAdvertsService extends BaseHomeEntitiesService<HomeAdvertsDataModel> {

  constructor() {
    super('/home-adverts');
  }

  public async getShortIds(query: HomePageQuery): Promise<string[]> {
    return this.sendGet<string[]>(`/short-ids${this.stringify(query)}`);
  }
}

export default HomeAdvertsService;
