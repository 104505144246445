import type { DeepReadonly } from '../../../../framework/base/common/constants/types/UtilityTypes';
import type { BreadcrumbData } from '../../../../framework/base/common/services/content/breadcrumbs/models/BreadcrumbData';
import type { LocationDetailWithGeoApiModel } from '../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import HomePageUrlQueryMapper from '../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../models/system/home/queries/HomePageQuery';
import AdvertsListPageContentService from '../advert/AdvertsListPageContentService';
import EntitiesListBreadcrumbsService, { getLocations } from './abstract/EntitiesListBreadcrumbsService';
import type { BreadcrumbSpec } from './models/BreadcrumbSpec';

const linksSpec: BreadcrumbSpec[] = [
  { requiredTaxonomy: undefined, clearTaxonomy: { type: undefined, animal: undefined, }, },
  { requiredTaxonomy: 'animal', clearTaxonomy: { type: undefined, }, },
  { requiredTaxonomy: 'type', clearTaxonomy: {}, },
];

class AdvertsListBreadcrumbsService extends EntitiesListBreadcrumbsService {

  constructor() {
    super(new AdvertsListPageContentService(undefined));
  }

  public createRssFeedAlternativeUrls(query: HomePageQuery, locationData: LocationDetailWithGeoApiModel | undefined): string[] {
    const result: string[] = [];

    const homePageUrlQueryMapper = HomePageUrlQueryMapper.factory();

    for (const categoryQuery of EntitiesListBreadcrumbsService.createTaxonomyHomePageQueries(linksSpec, query)) {
      const url = homePageUrlQueryMapper.generateHomePageFriendlyUrl(categoryQuery);

      result.push(`${this.origin}/feed/rss${url.pathname}`);
    }

    if (locationData) {
      const x = getLocations(locationData);
      const y = x[0];

      if (y) {
        const locationQuery: DeepReadonly<HomePageQuery> = {
          ...query,
          location: {
            id: y.id,
            slugs: [y.localNameSlug],
          },
        };

        const url = homePageUrlQueryMapper.generateHomePageFriendlyUrl(locationQuery);

        result.push(`${this.origin}/feed/rss${url.pathname}`);
      }
    }

    return result;
  }

  protected override createCateogryBreadcrumbs(query: HomePageQuery): BreadcrumbData[] {
    const result: BreadcrumbData[] = [];

    for (const categoryQuery of EntitiesListBreadcrumbsService.createTaxonomyHomePageQueries(linksSpec, query)) {
      const breadcrumbLinkData = this.createMainBreadcrumbLinkData(categoryQuery);
      const breadcrumbSchemaData = this.createMainBreadcrumbSchemaData(categoryQuery);
      const breadcrumbData = {
        link: breadcrumbLinkData,
        schema: breadcrumbSchemaData,
      };

      result.push(breadcrumbData);
    }

    return result;
  }
}

export default AdvertsListBreadcrumbsService;
